import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { useSiteMetadata } from '../../hooks/useSiteMetadata';

export default function Seo({ currentTitle, currentDescription, currentKeywords, currentRobots, meta, lang }) {
  const {
    title,
    author,
    siteUrl,
    imageUrl,
    keywords
  } = useSiteMetadata();

  const metaKeywords = currentKeywords || keywords;
  // const metaRobots = 'noindex, nofollow';
  const metaRobots = currentRobots || 'index, follow';

  return (
    <Helmet
      title={currentTitle}
      htmlAttributes={{ lang }}
      titleTemplate={`%s | ${title}`}
      meta={[
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          name: 'description',
          content: currentDescription,
        },
        {
          property: 'og:local',
          content: lang,
        },
        {
          property: 'og:title',
          content: `${currentTitle} | ${title}`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: siteUrl,
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          property: 'og:description',
          content: currentDescription,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: author,
        },
        {
          name: 'twitter:title',
          content: `${currentTitle} | ${title}`,
        },
        {
          name: 'twitter:description',
          content: currentDescription,
        },
        {
          name: 'robots',
          content: metaRobots,
        },
      ].concat(meta)}
    />
  );
};

Seo.defaultProps = {
  meta: [],
  lang: 'fr',
  currentTitle: '',
  currentKeywords: '',
  currentDescription: '',
};

Seo.propTypes = {
  lang: PropTypes.string,
  currentKeywords: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  currentTitle: PropTypes.string.isRequired,
  currentDescription: PropTypes.string.isRequired,
};
