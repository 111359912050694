import React from 'react';

import Seo from '../components/seo/Seo';
import PrivacyView from '../views/PrivacyPolicyView';

export default function PrivacyPolicy() {
  return (
    <>
      <Seo
        currentTitle="Politique de confidentialité"
        currentDescription="La politique de confidentialité sert à assurer une certaine transparence et rassurer les internautes sur l'identité des informations."
      />
      <PrivacyView />
    </>
  );
};
