import React from 'react';
import { Button } from 'gatsby-theme-material-ui';

import { Box } from '@mui/material';

import { Reveal } from '../hooks/reveal';
import { useStyles } from './legalView.styles';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { Subtitle, TextContent } from '../components/utils/utils';

export default function PrivacyPolicyView() {
  const { author, siteName } = useSiteMetadata();

  return (
    <Box component="section" className="legal">
      <Reveal>
        <Box mb={5}>
          {/* <Box mb={5}>
            <Title component="h1" sx={{ ...useStyles.title }}>
              Protection des données {author}
            </Title>
          </Box> */}
          <Subtitle component="h1">
            Que fait {author} pour protéger vos données personnelles ?
          </Subtitle>
          <TextContent>
            Désireux de protéger la vie privée de ses clients, {author} prend toutes les mesures les plus appropriées pour se mettre en conformité au Règlement Général européen sur la Protection des Données (RGPD), entré en vigueur au 25 mai 2018 et à la réglementation de l’Office fédéral de la justice (OFJ).
            <br />
            Par la présente charte, nous souhaitons vous informer sur les données que nous collectons, sur la manière dont ces informations sont utilisées et sur vos possibilités de nous informer de vos préférences sur l’utilisation et sur le traitement de ces données.
          </TextContent>
        </Box>
      </Reveal>
      <Reveal>
        <Box mb={5}>
          <Subtitle component="h2" sx={{ ...useStyles.title }}>
            Quelles données sont collectées et à quelles occasions ?
          </Subtitle>
          <Box>
            <TextContent>
              {author} récolte certaines de vos données personnelles, principalement dans le cadre de nos relations commerciales.
              Nous ne récoltons que les données qui sont nécessaires au bon fonctionnement de notre relation commerciale, à savoir :
            </TextContent>
            <Box component="ul" m={0} sx={{ ...useStyles.margin }}>
              <li>Le nom et prénom</li>
              <li>La date de naissance</li>
              <li>L’adresse postale</li>
              <li>L’adresse email</li>
              <li>Les numéros de téléphone des mobiles et des fixes</li>
            </Box>
          </Box>
          <Box>
            <TextContent>
              Les autres données qui peuvent être collectées pour des besoins plus spécifiques, seront nommées explicitement dans des mentions claires lors de leur collecte. Lors de votre visite sur notre site internet {siteName}, nous enregistrons provisoirement :
            </TextContent>
            <Box component="ul" m={0} sx={{ ...useStyles.margin }}>
              <li>L’adresse IP de l’ordinateur demandeur</li>
              <li>La date et l’heure de l’accès</li>
              <li>Le nom et l’URL des données consultées</li>
              <li>Le site Internet à partir duquel il a été accédé à notre domaine</li>
              <li>Le système d’exploitation de votre ordinateur et du navigateur que vous utilisez,</li>
              <li>Le pays d’où l’accès à notre site Internet a lieu</li>
            </Box>
          </Box>
          <TextContent>
            La récolte et le traitement de ces données se font en général à titre anonyme sans référence personnelle afin de permettre l’utilisation du site Internet (mise en place de la liaison), de garantir durablement la sécurité et la stabilité du système et d’optimiser l’offre Internet, ainsi qu’à des fins statistiques. Les informations susmentionnées ne sont pas reliées à des données personnelles ni enregistrées avec celles-ci.
          </TextContent>
        </Box>
      </Reveal>
      <Reveal>
        <Box mb={5}>
          <Subtitle component="h2" sx={{ ...useStyles.title }}>
            Pourquoi collectons-nous vos données personnelles ?
          </Subtitle>
          <TextContent>
            Vos données personnelles recueillies sont utilisées dans le cadre de notre relation contractuelle et commerciale :
          </TextContent>
          <Box component="ul" mt={0}>
            <li>Pour la gestion du compte client par {author}</li>
            <li>Pour l’adaptation de notre offre en vous informant des évolutions de nos produits et pour les invitations à des événements.</li>
          </Box>
          <TextContent>
            {author} s’engage à ne pas utiliser vos données personnelles à d’autres fins que celles prévues dans la présente charte, et à ne pas les transmettre à des tiers.
          </TextContent>
        </Box>
      </Reveal>
      <Reveal>
        <Box mb={5}>
          <Subtitle component="h2" sx={{ ...useStyles.title }}>
            Qui sont les destinataires de vos données personnelles ?
          </Subtitle>
          <TextContent paragraph={true}>
            Dans le strict cadre des finalités énoncées, les destinataires de vos données sont le personnel de {author}.<br />
            Les personnes qui doivent consulter vos données sont sensibilisés aux principes de protection des données personnelles au sens de la loi Suisse et du RGPD européen.<br />
            Dans le cas où {author} fait appel à un sous-traitant, {author} s’assure que celui-ci présente des garanties suffisantes pour la protection de vos droits.
          </TextContent>
        </Box>
      </Reveal>
      <Reveal>
        <Box mb={5}>
          <Subtitle component="h2" sx={{ ...useStyles.title }}>
            Quelle est la durée de conservation de vos données personnelles ?
          </Subtitle>
          <TextContent>
            La durée de conservation de vos données personnelles correspond à la durée de la relation commerciale de nos activités avec vous, de la prospection à la vente, ainsi que durant toute la période où vous faites appel à notre service après-vente.<br />
            Elle peut se poursuivre pour un nombre limité de finalités telles que cycle de facturation, conformité aux exigences légales, fiscales et/ou contractuelles, y compris en cas d’audit interne.
          </TextContent>
        </Box>
      </Reveal>
      <Reveal>
        <Box mb={5}>
          <Subtitle component="h2" sx={{ ...useStyles.title }}>
            De quels droits disposez-vous pour la protection de vos données personnelles ?
          </Subtitle>
          <Box component="ul" m={0} sx={{ ...useStyles.margin }}>
            <li><strong>Droit d’opposition :</strong> Lorsque vos données personnelles ne sont pas ou plus nécessaires dans notre relation vous pouvez demander à ce qu’elles ne fassent plus l’objet de traitement.</li>
            <li><strong>Droit d’accès et de rectification :</strong> À tout moment vous pouvez demander l’accès à vos données personnelles et à la rectification de celles-ci.</li>
            <li><strong>Droit à une limitation de traitement :</strong> Vous pouvez nous faire part de votre refus de traitement pour des actions spécifiques.</li>
            <li><strong>Droit à la portabilité de vos données :</strong> À tout moment vous pouvez demander une copie des données que nous avons collectées. Vous pouvez nous demander de communiquer celles-ci à la personne de votre choix.</li>
          </Box>
          <TextContent>
            Pour faire valoir l’un de ces droits, il vous suffit de nous adresser votre demande soit par e-mail à data.protection@autobritt.ch ou par écrit directement à {author}.
          </TextContent>
        </Box>
      </Reveal>
      <Reveal>
        <Button
          to="/"
          size="large"
          variant="contained"
        >
          Retour à l'accueil
        </Button>
      </Reveal>
    </Box>
  );
};